import React from "react"
import { Box } from "@chakra-ui/react"
import logo from '../assets/logo-campfire.png'

export default function LogoCampfire(props) {
  return (
    <Box width={['40%', '60%', '60%']} paddingBottom={props.paddingBottom}>
        <img className={props.className} src={logo} alt="Logo" />
    </Box>
  )
}