import AppRouter from "./AppRouter";
function Main() {
    return (
        <div className="App">
            <AppRouter />
        </div>  
    );
}
 
export default Main;

